@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,600;1,700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap'); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Libre Franklin', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit
}

ul, ol {
  padding-left: 16px;
}

